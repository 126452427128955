import React from 'react'
import { Link } from 'gatsby'
import PropTypes from 'prop-types'

// Components
import PageLayout from '../components/Layout/PageLayout'

const About = ({ location }) => {
  return (
    <PageLayout location={location} title="About" pageTitle="About Us">
      <p>Welcome aboard!</p>
      <p>
        We are a small team of energetic folks who are incredibly passionate about everything
        related to model trains. We strive to bring you top-quality track plans from brands like
        Lionel, MTH Electric Trains, and Weaver – at convenient pricing so that you don’t have to
        break the bank.
      </p>
      <p>
        Not only do we want to be the best at what we do, but we also aim to provide collectors with
        the best customer service possible – so our{' '}
        <Link className="text-link" to={'/contact'}>
          inbox
        </Link>{' '}
        is always open for questions, suggestions and proposals. We offer multiple sizing options,
        including custom-made track plans to perfectly fit into your space. Regardless of your
        order, we make sure you’ll be pleasantly surprised when you unbox your delivery. You can
        always count on Franklin Trains for transparency, reliability, and top quality.
      </p>
    </PageLayout>
  )
}

About.propTypes = {
  location: PropTypes.object.isRequired,
}

export default About
